import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "currentColor",
  viewBox: "0 0 11 10"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "m5.727.234 1.545 3.13 3.455.505-2.5 2.435.59 3.44-3.09-1.625-3.09 1.625.59-3.44-2.5-2.435 3.455-.505z" }, null, -1)
  ])))
}
export default { render: render }